/* --- [ Backgrounds ] --- */

$bg-facebook:                     #3b5998;
$bg-twitter:                      #00aced;
$bg-google:                       #dd4b39;
$bg-linkedin:                     #007bb6;
$bg-youtube:                      #bb0000;
$bg-instagram:                    #517fa4;
$bg-pinterest:                    #cb2027;
$bg-flickr:                       #ff0084;
$bg-tumblr:                       #32506d;
$bg-foursquare:                   #0072b1;
$bg-dribbble:                     #ea4c89;
$bg-vine:                         #00bf8f;
$bg-skype:                        #17a3eb;
$bg-wordpress:                    #2592c3;
$bg-behance:                      #1879fd;
$bg-soundcloud:                   #ff7e30;
$bg-stumbleupon:                  #ff5c30;
$bg-deviantart:                   #6a8a7b;
$bg-yahoo:                        #ab47ac;
$bg-digg:                         #75788d;
$bg-github:                       #3f91cb;
$bg-lastfm:                       #f34320;
$bg-vk:                           #2b587a;

/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~ v1.0.0 ~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */

.bg-dark {
	background-color: #1e2328;
}

.bg-brand {
	background-color: $brand-primary !important;
}

.bg-brand1 {
	background-color: $brand1 !important;
}

.bg-brand2 {
	background-color: $brand2 !important;
}

.bg-brand3 {
	background-color: $brand3 !important;
}

.bg-brand-hvr {
	background-color: darken($brand-primary,12%) !important;
}

.bg-facebook {
	background-color: $bg-facebook !important;
}

.bg-twitter {
	background-color: $bg-twitter !important;
}

.bg-google {
	background-color: $bg-google !important;
}

.bg-linkedin {
	background-color: $bg-linkedin !important;
}

.bg-youtube {
	background-color: $bg-youtube !important;
}

.bg-instagram {
	background-color: $bg-instagram !important;
}

.bg-pinterest {
	background-color: $bg-pinterest !important;
}

.bg-flickr {
	background-color: $bg-flickr !important;
}

.bg-tumblr {
	background-color: $bg-tumblr !important;
}

.bg-foursquare {
	background-color: $bg-foursquare !important;
}

.bg-dribbble {
	background-color: $bg-dribbble !important;
}

.bg-vine {
	background-color: $bg-vine !important;
}

.bg-skype {
	background-color: $bg-skype !important;
}

.bg-wordpress {
	background-color: $bg-wordpress !important;
}

.bg-behance {
	background-color: $bg-behance !important;
}

.bg-soundcloud {
	background-color: $bg-soundcloud !important;
}

.bg-stumbleupon {
	background-color: $bg-stumbleupon !important;
}

.bg-deviantart {
	background-color: $bg-deviantart !important;
}

.bg-yahoo {
	background-color: $bg-yahoo !important;
}

.bg-digg {
	background-color: $bg-digg !important;
}

.bg-github {
	background-color: $bg-github !important;
}

.bg-lastfm {
	background-color: $bg-lastfm !important;
}

.bg-vk {
	background-color: $bg-vk !important;
}

/* --- [ Alfa backgrounds ] --- */

.bg-black-alfa-5:before,
.bg-black-alfa-10:before,
.bg-black-alfa-15:before,
.bg-black-alfa-20:before,
.bg-black-alfa-25:before,
.bg-black-alfa-30:before,
.bg-black-alfa-35:before,
.bg-black-alfa-40:before,
.bg-black-alfa-45:before,
.bg-black-alfa-50:before,
.bg-black-alfa-55:before,
.bg-black-alfa-60:before,
.bg-black-alfa-65:before,
.bg-black-alfa-70:before,
.bg-black-alfa-75:before,
.bg-black-alfa-80:before,
.bg-black-alfa-85:before,
.bg-black-alfa-90:before,
.bg-black-alfa-95:before {
	position: absolute;
	height: 100%;
	width: 100%;
	z-index: 0;
	content: "";
	left: 0;
	top: 0;
}

.bg-black-alfa-5:before,
.bg-black-alfa-10:before,
.bg-black-alfa-15:before,
.bg-black-alfa-20:before,
.bg-black-alfa-25:before,
.bg-black-alfa-30:before,
.bg-black-alfa-35:before,
.bg-black-alfa-40:before,
.bg-black-alfa-45:before,
.bg-black-alfa-50:before,
.bg-black-alfa-55:before,
.bg-black-alfa-60:before,
.bg-black-alfa-65:before,
.bg-black-alfa-70:before,
.bg-black-alfa-75:before,
.bg-black-alfa-80:before,
.bg-black-alfa-85:before,
.bg-black-alfa-90:before,
.bg-black-alfa-95:before {
	background: rgba(0, 0, 0, 0.05);
}

.bg-black-alfa-10:before {
	background: rgba(0, 0, 0, 0.1);
}

.bg-black-alfa-15:before {
	background: rgba(0, 0, 0, 0.15);
}

.bg-black-alfa-20:before {
	background: rgba(0, 0, 0, 0.2);
}

.bg-black-alfa-25:before {
	background: rgba(0, 0, 0, 0.25);
}

.bg-black-alfa-30:before {
	background: rgba(0, 0, 0, 0.3);
}

.bg-black-alfa-35:before {
	background: rgba(0, 0, 0, 0.35);
}

.bg-black-alfa-40:before {
	background: rgba(0, 0, 0, 0.4);
}

.bg-black-alfa-45:before {
	background: rgba(0, 0, 0, 0.45);
}

.bg-black-alfa-50:before {
	background: rgba(0, 0, 0, 0.5);
}

.bg-black-alfa-55:before {
	background: rgba(0, 0, 0, 0.55);
}

.bg-black-alfa-60:before {
	background: rgba(0, 0, 0, 0.6);
}

.bg-black-alfa-65:before {
	background: rgba(0, 0, 0, 0.65);
}

.bg-black-alfa-70:before {
	background: rgba(0, 0, 0, 0.7);
}

.bg-black-alfa-75:before {
	background: rgba(0, 0, 0, 0.75);
}

.bg-black-alfa-80:before {
	background: rgba(0, 0, 0, 0.8);
}

.bg-black-alfa-85:before {
	background: rgba(0, 0, 0, 0.85);
}

.bg-black-alfa-90:before {
	background: rgba(0, 0, 0, 0.9);
}

.bg-black-alfa-95:before {
	background: rgba(0, 0, 0, 0.95);
}

.bg-white-alfa-5:before,
.bg-white-alfa-10:before,
.bg-white-alfa-15:before,
.bg-white-alfa-20:before,
.bg-white-alfa-25:before,
.bg-white-alfa-30:before,
.bg-white-alfa-35:before,
.bg-white-alfa-40:before,
.bg-white-alfa-45:before,
.bg-white-alfa-50:before,
.bg-white-alfa-55:before,
.bg-white-alfa-60:before,
.bg-white-alfa-65:before,
.bg-white-alfa-70:before,
.bg-white-alfa-75:before,
.bg-white-alfa-80:before,
.bg-white-alfa-85:before,
.bg-white-alfa-90:before,
.bg-white-alfa-95:before {
	position: absolute;
	height: 100%;
	width: 100%;
	z-index: 0;
	content: "";
	left: 0;
	top: 0;
}

.bg-white-alfa-5:before,
.bg-white-alfa-10:before,
.bg-white-alfa-15:before,
.bg-white-alfa-20:before,
.bg-white-alfa-25:before,
.bg-white-alfa-30:before,
.bg-white-alfa-35:before,
.bg-white-alfa-40:before,
.bg-white-alfa-45:before,
.bg-white-alfa-50:before,
.bg-white-alfa-55:before,
.bg-white-alfa-60:before,
.bg-white-alfa-65:before,
.bg-white-alfa-70:before,
.bg-white-alfa-75:before,
.bg-white-alfa-80:before,
.bg-white-alfa-85:before,
.bg-white-alfa-90:before,
.bg-white-alfa-95:before {
	background: rgba(255, 255, 255, 0.05);
}

.bg-white-alfa-10:before {
	background: rgba(255, 255, 255, 0.1);
}

.bg-white-alfa-15:before {
	background: rgba(255, 255, 255, 0.15);
}

.bg-white-alfa-20:before {
	background: rgba(255, 255, 255, 0.2);
}

.bg-white-alfa-25:before {
	background: rgba(255, 255, 255, 0.25);
}

.bg-white-alfa-30:before {
	background: rgba(255, 255, 255, 0.3);
}

.bg-white-alfa-35:before {
	background: rgba(255, 255, 255, 0.35);
}

.bg-white-alfa-40:before {
	background: rgba(255, 255, 255, 0.4);
}

.bg-white-alfa-45:before {
	background: rgba(255, 255, 255, 0.45);
}

.bg-white-alfa-50:before {
	background: rgba(255, 255, 255, 0.5);
}

.bg-white-alfa-55:before {
	background: rgba(255, 255, 255, 0.55);
}

.bg-white-alfa-60:before {
	background: rgba(255, 255, 255, 0.6);
}

.bg-white-alfa-65:before {
	background: rgba(255, 255, 255, 0.65);
}

.bg-white-alfa-70:before {
	background: rgba(255, 255, 255, 0.7);
}

.bg-white-alfa-75:before {
	background: rgba(255, 255, 255, 0.75);
}

.bg-white-alfa-80:before {
	background: rgba(255, 255, 255, 0.8);
}

.bg-white-alfa-85:before {
	background: rgba(255, 255, 255, 0.85);
}

.bg-white-alfa-90:before {
	background: rgba(255, 255, 255, 0.9);
}

.bg-white-alfa-95:before {
	background: rgba(255, 255, 255, 0.95);
}

.bg-brand-alfa-5:before,
.bg-brand-alfa-10:before,
.bg-brand-alfa-15:before,
.bg-brand-alfa-20:before,
.bg-brand-alfa-25:before,
.bg-brand-alfa-30:before,
.bg-brand-alfa-35:before,
.bg-brand-alfa-40:before,
.bg-brand-alfa-45:before,
.bg-brand-alfa-50:before,
.bg-brand-alfa-55:before,
.bg-brand-alfa-60:before,
.bg-brand-alfa-65:before,
.bg-brand-alfa-70:before,
.bg-brand-alfa-75:before,
.bg-brand-alfa-80:before,
.bg-brand-alfa-85:before,
.bg-brand-alfa-90:before,
.bg-brand-alfa-95:before {
	position: absolute;
	height: 100%;
	width: 100%;
	z-index: 0;
	content: "";
	left: 0;
	top: 0;
}

.bg-brand-alfa-5:before,
.bg-brand-alfa-10:before,
.bg-brand-alfa-15:before,
.bg-brand-alfa-20:before,
.bg-brand-alfa-25:before,
.bg-brand-alfa-30:before,
.bg-brand-alfa-35:before,
.bg-brand-alfa-40:before,
.bg-brand-alfa-45:before,
.bg-brand-alfa-50:before,
.bg-brand-alfa-55:before,
.bg-brand-alfa-60:before,
.bg-brand-alfa-65:before,
.bg-brand-alfa-70:before,
.bg-brand-alfa-75:before,
.bg-brand-alfa-80:before,
.bg-brand-alfa-85:before,
.bg-brand-alfa-90:before,
.bg-brand-alfa-95:before {
	background: rgba($brand-primary, 0.05);
}

.bg-brand-alfa-10:before {
	background: rgba($brand-primary, 0.1);
}

.bg-brand-alfa-15:before {
	background: rgba($brand-primary, 0.15);
}

.bg-brand-alfa-20:before {
	background: rgba($brand-primary, 0.2);
}

.bg-brand-alfa-25:before {
	background: rgba($brand-primary, 0.25);
}

.bg-brand-alfa-30:before {
	background: rgba($brand-primary, 0.3);
}

.bg-brand-alfa-35:before {
	background: rgba($brand-primary, 0.35);
}

.bg-brand-alfa-40:before {
	background: rgba($brand-primary, 0.4);
}

.bg-brand-alfa-45:before {
	background: rgba($brand-primary, 0.45);
}

.bg-brand-alfa-50:before {
	background: rgba($brand-primary, 0.5);
}

.bg-brand-alfa-55:before {
	background: rgba($brand-primary, 0.55);
}

.bg-brand-alfa-60:before {
	background: rgba($brand-primary, 0.6);
}

.bg-brand-alfa-65:before {
	background: rgba($brand-primary, 0.65);
}

.bg-brand-alfa-70:before {
	background: rgba($brand-primary, 0.7);
}

.bg-brand-alfa-75:before {
	background: rgba($brand-primary, 0.75);
}

.bg-brand-alfa-80:before {
	background: rgba($brand-primary, 0.8);
}

.bg-brand-alfa-85:before {
	background: rgba($brand-primary, 0.85);
}

.bg-brand-alfa-90:before {
	background: rgba($brand-primary, 0.9);
}

.bg-brand-alfa-95:before {
	background: rgba($brand-primary, 0.95);
}









.bg-brand-dark-alfa-5:before,
.bg-brand-dark-alfa-10:before,
.bg-brand-dark-alfa-15:before,
.bg-brand-dark-alfa-20:before,
.bg-brand-dark-alfa-25:before,
.bg-brand-dark-alfa-30:before,
.bg-brand-dark-alfa-35:before,
.bg-brand-dark-alfa-40:before,
.bg-brand-dark-alfa-45:before,
.bg-brand-dark-alfa-50:before,
.bg-brand-dark-alfa-55:before,
.bg-brand-dark-alfa-60:before,
.bg-brand-dark-alfa-65:before,
.bg-brand-dark-alfa-70:before,
.bg-brand-dark-alfa-75:before,
.bg-brand-dark-alfa-80:before,
.bg-brand-dark-alfa-85:before,
.bg-brand-dark-alfa-90:before,
.bg-brand-dark-alfa-95:before {
	position: absolute;
	height: 100%;
	width: 100%;
	z-index: 0;
	content: "";
	left: 0;
	top: 0;
}

.bg-brand-dark-alfa-5:before,
.bg-brand-dark-alfa-10:before,
.bg-brand-dark-alfa-15:before,
.bg-brand-dark-alfa-20:before,
.bg-brand-dark-alfa-25:before,
.bg-brand-dark-alfa-30:before,
.bg-brand-dark-alfa-35:before,
.bg-brand-dark-alfa-40:before,
.bg-brand-dark-alfa-45:before,
.bg-brand-dark-alfa-50:before,
.bg-brand-dark-alfa-55:before,
.bg-brand-dark-alfa-60:before,
.bg-brand-dark-alfa-65:before,
.bg-brand-dark-alfa-70:before,
.bg-brand-dark-alfa-75:before,
.bg-brand-dark-alfa-80:before,
.bg-brand-dark-alfa-85:before,
.bg-brand-dark-alfa-90:before,
.bg-brand-dark-alfa-95:before {
	background: rgba($brand3-darker, 0.05);
}

.bg-brand-dark-alfa-10:before {
	background: rgba($brand3-darker, 0.1);
}

.bg-brand-dark-alfa-15:before {
	background: rgba($brand3-darker, 0.15);
}

.bg-brand-dark-alfa-20:before {
	background: rgba($brand3-darker, 0.2);
}

.bg-brand-dark-alfa-25:before {
	background: rgba($brand3-darker, 0.25);
}

.bg-brand-dark-alfa-30:before {
	background: rgba($brand3-darker, 0.3);
}

.bg-brand-dark-alfa-35:before {
	background: rgba($brand3-darker, 0.35);
}

.bg-brand-dark-alfa-40:before {
	background: rgba($brand3-darker, 0.4);
}

.bg-brand-dark-alfa-45:before {
	background: rgba($brand3-darker, 0.45);
}

.bg-brand-dark-alfa-50:before {
	background: rgba($brand3-darker, 0.5);
}

.bg-brand-dark-alfa-55:before {
	background: rgba($brand3-darker, 0.55);
}

.bg-brand-dark-alfa-60:before {
	background: rgba($brand3-darker, 0.6);
}

.bg-brand-dark-alfa-65:before {
	background: rgba($brand3-darker, 0.65);
}

.bg-brand-dark-alfa-70:before {
	background: rgba($brand3-darker, 0.7);
}

.bg-brand-dark-alfa-75:before {
	background: rgba($brand3-darker, 0.75);
}

.bg-brand-dark-alfa-80:before {
	background: rgba($brand3-darker, 0.8);
}

.bg-brand-dark-alfa-85:before {
	background: rgba($brand3-darker, 0.85);
}

.bg-brand-dark-alfa-90:before {
	background: rgba($brand3-darker, 0.9);
}

.bg-brand-dark-alfa-95:before {
	background: rgba($brand3-darker, 0.95);
}
