/*------------------------------------------------------------------
[Breadcrumb]
*/

$breadcrumb-a-color: #999;
$breadcrumb-a-color-hover: $brand-primary;
$breadcrumb-a-color-active: #444;
$breadcrumb-li-seperator-color: #999;

/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~ v1.0.0 ~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */

.breadcrumb {
	background: none;
	padding: 0;
	margin: 0;
	a {
		color: $breadcrumb-a-color;
		&:hover {
			color: $breadcrumb-a-color-hover;
		}
		&:focus {
			color: $breadcrumb-a-color-hover;
		}
	}
	> li + li:before {
		color: $breadcrumb-li-seperator-color;
		padding: 0 8px;
	}
	> .active {
		color: $breadcrumb-a-color-active;
		opacity: 0.9;
	}
}

.breadcrumb ul {

	margin:0;
	padding:0;
	list-style: none;

	li {
		float:left;
		height:32px;
		margin:0;
		padding:0 15px 0 0;

		a {
			padding:8px 0;
			color: $pure-white;
			font-size: 14px;
			line-height: 14px;
		}
	}

}
