/*------------------------------------------------------------------
[Blog]
*/

$post-meta-li-color: #999;

$post-columns-post-bg: #f8f8f8;

$comment-content-bg: #f5f5f5;
$comment-avatar-bg: #f5f5f5;
$comment-tools-a-color: #999;
$comment-tools-a-color-hover: $brand-primary;

$tags-a-bg: #f8f8f8;
$tags-a-color: #666;
$tags-a-bg-hover: $brand-primary;
$tags-a-color-hover: #fff;

/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~ v1.0.0 ~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */

.news .module {
	padding: 0 0 140px 0;
}

.post {
	margin: 20px 0 50px;
	.post-title {
		font-size: 23px;
		margin-bottom: 10px;
	}
	.post-content {
		padding: 30px 0 0 0;
	}
	&.post-featured .post-content {
		padding: 30px 0 0;
	}
}
.post-meta {
	list-style: none;
	margin: 0 0 25px;
	padding: 0;
	> li {
		display: inline-block;
		text-transform: uppercase;
		letter-spacing: 1px;
		font-weight: 700;
		font-size: 11px;
		color: $post-meta-li-color;
		+ li:before {
			content: "/\00a0";
			padding: 0 5px;
		}
	}
}

.comments {
	margin-bottom: 70px;
	.bottom-line:after {
		margin-bottom: 35px;
	}
}
.comment-form {
	margin-bottom: 70px;
	.bottom-line:after {
		margin-bottom: 35px;
	}
}
.comment {
	position: relative;
	&:before {
		content: " ";
		display: table;
	}
	&:after {
		content: " ";
		display: table;
		clear: both;
	}
	&:last-child .comment-content {
		margin-bottom: 0;
	}
}
.comment-content {
	position: relative;
	position: relative;
	background: $comment-content-bg;
	margin: 0 0 35px 90px;
	padding: 30px;
	&:before {
		content: " ";
		display: table;
	}
	&:after {
		content: " ";
		display: table;
		clear: both;
	}
	> *:last-child {
		margin-bottom: 0;
	}
}
.comment-avatar {
	position: relative;
	max-width: 70px;
	margin: 5px 0 0;
	float: left;
	z-index: 1;
	&:after {
		position: absolute;
		background: $comment-avatar-bg;
		content: "";
		height: 2px;
		width: 20px;
		top: 34px;
		left: 70px;
		z-index: -1;
	}
	img {
		width: 70px;
		height: 70px;
		border-radius: 50%;
	}
}
.comment-meta {
	font-size: 12px;
	opacity: 0.7;
}
.comment-tools {
	position: absolute;
	padding: 0 30px;
	z-index: 1;
	top: 25px;
	right: 0;
	a {
		color: $comment-tools-a-color;
		margin: 0 0 0 15px;
		&:hover {
			color: $comment-tools-a-color-hover;
		}
		&:focus {
			color: $comment-tools-a-color-hover;
		}
	}
}
@media(min-width: $screen-sm-min) {
	.comment-reply {
		margin-left: 90px;
	}
}
.post-columns .post {
	background: $post-columns-post-bg;
	margin: 0 0 30px;
	.post-title {
		font-size: 20px;
	}
	.post-content {
		padding: 30px 25px;
		> *:last-child {
			margin-bottom: 0;
		}
	}
}
.tags a {
	background: $tags-a-bg;
	display: inline-block;
	border-radius: $brand-sitewide-radius;
	padding: 8px 12px;
	margin: 0 0 6px;
	font-size: 11px;
	color: $tags-a-color;
	&:hover {
		background: $tags-a-bg-hover;
		color: $tags-a-color-hover;
	}
	&:focus {
		background: $tags-a-bg-hover;
		color: $tags-a-color-hover;
	}
}

@media(max-width: $screen-xs-max) {
	.post .post-title {
		font-size: 20px;
		font-weight:400;
		text-align:center;
	}
	.post .post-content {
		padding: 30px 0 0;
	}
	.blog-content + .sidebar {
		margin: 70px 0 0;
	}
	.sidebar + .blog-content {
		margin: 70px 0 0;
	}
}

.blog-page-nav {
	text-align:center;
}
