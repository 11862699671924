/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~ v1.0.0 ~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */

//
// Buttons
// --------------------------------------------------


@mixin btn-variant($color, $background, $border) {
	color: $color;
	background-color: $background;
	border-color: $border;

	&:focus,
	&.focus {
		color: $color;
		background-color: darken($background, 10%);
		border-color: darken($border, 10%);
	}
	&:hover {
		color: $color;
		background-color: darken($background, 10%);
		border-color: darken($border, 10%);
	}
	&:active,
	&.active,
	.open > &.dropdown-toggle {
		color: $color;
		background-color: darken($background, 10%);
		border-color: darken($border, 10%);

		&:hover,
		&:focus,
		&.focus {
			color: $color;
			background-color: darken($background, 20%);
			border-color: darken($border, 20%);
		}
	}
	&:active,
	&.active,
	.open > &.dropdown-toggle {
		background-image: none;
	}
	&.disabled,
	&[disabled],
	fieldset[disabled] & {
		&:hover,
		&:focus,
		&.focus {
			background-color: $background;
			border-color: $border;
		}
	}

	&.btn-outline {
		background: transparent;
		border-color: $background;
		border-width:1px;
		color: $background;
	}
	&.btn-outline:hover,
	&.btn-outline:focus {
		background: $background;
		color: $color;
	}

	&.btn-link {
		background: transparent;
		border: 0;
		padding: 0;
		color: $background;
	}
	&.btn-link:hover,
	&.btn-link:focus {
		background-color: transparent;
		text-decoration: none;
		color: darken($background, 10%);
	}

	&.btn-fade {
		background: transparent;
		border-color: rgba($background, 0.5);
		color: rgba($background, 0.5);
	}
	&.btn-fade:hover,
	&.btn-fade:focus {
		background: transparent;
		border-color: $background;
		color: $background;
	}


	.badge {
		color: $background;
		background-color: $color;
	}
}



