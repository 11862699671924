/*------------------------------------------------------------------
[Modules]
*/

$module-row-padding:              70px;
$module-row-padding-sm:           40px;
$module-row-padding-xs:           20px;

$module-border-color:             #eee;
$module-border-style:             solid;
$module-border-width:             1px;

@media(min-width: $screen-sm-min) and(max-width: $screen-sm-max) {
	.container {
		// extra padding for tablet
		padding-left: floor(($grid-gutter-width / 2));
		padding-right: floor(($grid-gutter-width / 2));
	}
}
@media(min-width: $screen-lg-min) {
	.container {
		// extra padding for desktop
		padding-left: $grid-gutter-width;
		padding-right: $grid-gutter-width;
	}
}
.module {
	background-size: cover;
	background-repeat: no-repeat;
	background-position: 50% 0;
	position: relative;
	padding-top: $module-row-padding;
	padding-right: 0;
	padding-bottom: $module-row-padding;
	padding-left: 0;
	&.module-main {
		padding: 0;
	}
	&.divider-top {
		border-top-color: $module-border-color;
		border-top-style: $module-border-style;
		border-top-width: $module-border-width;
	}
	&.divider-bottom {
		border-bottom-color: $module-border-color;
		border-bottom-style: $module-border-style;
		border-bottom-width: $module-border-width;
	}
	&.parallax {
		background-color: transparent;
	}
}
.module-sm {
	background-size: cover;
	background-repeat: no-repeat;
	background-position: 50% 0;
	position: relative;
	padding-top: $module-row-padding-sm;
	padding-right: 0;
	padding-bottom: $module-row-padding-sm;
	padding-left: 0;
	&.divider-top {
		border-top-color: $module-border-color;
		border-top-style: $module-border-style;
		border-top-width: $module-border-width;
	}
	&.divider-bottom {
		border-bottom-color: $module-border-color;
		border-bottom-style: $module-border-style;
		border-bottom-width: $module-border-width;
	}
	&.parallax {
		background-color: transparent;
		background-position:50% 50%;
	}
}
.module-xs {
	background-size: cover;
	background-repeat: no-repeat;
	background-position: 50% 0;
	position: relative;
	padding-top: $module-row-padding-xs;
	padding-right: 0;
	padding-bottom: $module-row-padding-xs;
	padding-left: 0;
	padding: 35px 0;
	&.divider-top {
		border-top-color: $module-border-color;
		border-top-style: $module-border-style;
		border-top-width: $module-border-width;
	}
	&.divider-bottom {
		border-bottom-color: $module-border-color;
		border-bottom-style: $module-border-style;
		border-bottom-width: $module-border-width;
	}
	&.parallax {
		background-color: transparent;
		background-position:50% 50%;
	}
}
.module-header {
	margin: 0 0 70px;
	h1 {
		margin-bottom: 10px;
	}
	.h1 {
		margin-bottom: 10px;
	}
	h2 {
		margin-bottom: 10px;
	}
	.h2 {
		margin-bottom: 10px;
	}
	h3 {
		margin-bottom: 10px;
	}
	.h3 {
		margin-bottom: 10px;
	}
	h4 {
		margin-bottom: 10px;
	}
	.h4 {
		margin-bottom: 10px;
	}
	h5 {
		margin-bottom: 10px;
	}
	.h5 {
		margin-bottom: 10px;
	}
	h6 {
		margin-bottom: 10px;
	}
	.h6 {
		margin-bottom: 10px;
	}
}
.no-gutter {
	margin-right: 0;
	margin-left: 0;
	> [class*="col-"] {
		padding-left: 0;
		padding-right: 0;
	}
}
.pull-image {
	.container {
		position: relative;
		> div[class*='col-'] {
			position: absolute;
		}
	}
	.vertical-middle {
		position: relative;
		top: 50%;
		z-index: 2;
		-webkit-transform: translate3d(0, -50%, 0);
		transform: translate3d(0, -50%, 0);
	}
	.pull-image-right {
		right: 0;
	}
}
@media(max-width: $screen-sm-max) {
	.pull-image .container div[class*='col-'] {
		position: relative;
		top: 0;
		-webkit-transform: none;
		transform: none;
	}
}
.side-background {
	position: relative;
	padding: 0;
	.background-container {
		position: absolute;
		overflow: hidden;
		height: 100%;
		padding: 0;
		top: 0;
	}
	.background-side {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-size: cover !important;
		z-index: 0;
		background-position: 50% 50% !important;
		&.start-top {
			background-position: 0 0 !important;
		}
		&.start-bottom {
			background-position: 100% 0 !important;
		}
	}
	.content-side {
		padding-top: 140px;
		padding-bottom: 140px;
	}
}

// mobile portrait ( < 480)
.content-full-width {
	.content-container {
		padding-left: 0;
		padding-right: 0;
	}
}
.content-sidebar-right {
	.content-container {
		padding-left: 0;
		padding-right: 0;
	}
}


@media(min-width: $screen-xs-min) and (max-width: $screen-xs-max) {

	// mobile landscape (480 < 768)

	.content-full-width {
		.content-container {
			padding-left: 0;
			padding-right: 0;
		}
	}

	.content-sidebar-right {
		.content-container {
			padding-left: 0;
			padding-right: 0;
		}
	}

}

@media(min-width: $screen-sm-min) and (max-width: $screen-sm-max) {

	// tablet (768 - 1024)
	.content-full-width {
		.content-container {
			padding-left: 20px;
			padding-right: 20px;
		}
	}
	.content-sidebar-right {
		.content-container {
			padding-left: 20px;
			padding-right: 20px;
		}
	}
}

@media(min-width: $screen-md-min) and (max-width: $screen-md-max) {

	// small desktop (1024 - 1200)
	.content-full-width {
		.content-container {
			padding-left: 20px;
			padding-right: 20px;
		}
	}
	.content-sidebar-right {
		.content-container {
			padding-left: 20px;
			padding-right: 20px;
		}
	}
}

@media(min-width: $screen-lg-min) {

	// large desktop ( > 1200 )
	.content-full-width {
		.content-container {
			padding-left: 50px;
			padding-right: 50px;
		}
	}
	.content-sidebar-right {
		.content-container {
			padding-left: 60px;
			padding-right: 60px;
		}
	}
}



/*
.module-main .container-main > .row {
	padding-bottom: $module-row-padding;
	&:first-child {
		padding-top: $module-row-padding;
		padding-bottom: $module-row-padding;
	}
}
*/