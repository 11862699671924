/*------------------------------------------------------------------
[Maps]
*/

.maps-container {
	height: 500px;
}

#map-canvas {
	height: 100% !important;
	width: 100%;
}

.no-map-scroll {
	position: relative;
	.click-map {
		width: 100%;
		height: 500px;
		position: absolute;
		top: 0;
		z-index:999;
	}
}

.location-text .row {
	padding-bottom: 40px;
}

