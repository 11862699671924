.g-recaptcha {
	display: inline-block; // needed for centering
}

.hfont.lato {
	font-family: 'Lato', Helvetica, Arial, serif;
	letter-spacing: 4px;
}

@media(min-width: $screen-md-min) {

	.inner-nav {
		> li {
			> a {
				border-bottom: solid 4px #fff;

				&:hover {
					border-bottom: solid 4px $brand3;
				}
				&:focus {
					border-bottom: solid 4px $brand3;
				}
			}
			&.submenu-open > a {
				border-bottom: solid 4px $brand3;
			}
		}
	}

	.submenu {
		box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
	}

}

.header.header-small {
	box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1);
}


// Home Blog items
.home-blog-col {
	.home-blog-item {
		position: relative;
		.blog_title-bg {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			height: 100%;
			background: rgba(0, 0, 0, 0) linear-gradient(rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 40%, rgba(0, 0, 0, 0.2) 50%, rgba(0, 0, 0, 0.9) 100%) repeat scroll 0 0;
		}
		.blog-title {
			position: absolute;
			width: 100%;
			right: 0;
			bottom: 0;
			left: 0;
			h2 {
				margin: 0;
				a {
					display: block;
					padding: 15px;
					color: #fff;
					font-size: 20px;
					font-weight: 400;
					line-height: 1.4
				}
			}
		}
	}
}

.home-about h3 {
	margin: 30px 0;
	font-size: 24px;
	font-weight: 300;
	font-style: italic;
	color: #8ca0b4;

}

.subcat-item {

	padding:20px;

	.subcat-item-inner {

		position: relative;
		border: solid 1px $brand2;

		.subcat-title-bg {
			position: absolute;
			height: 60px;
			background-color: $brand2;
			bottom: 0;
			right: 0;
			left: 0;
		}
		.subcat-title {
			position: absolute;
			height: 60px;
			padding:15px;
			bottom: 0;
			right: 0;
			left: 0;
			h3 {
				font-size: 24px;
				font-weight: 400;
				color:#fff;
			}
		}
	}
}

.breadcrumb {
	min-height: 24px;
}

#map-canvas {

	h3 {
		font-size:16px;
	}
	p {
		margin: 0 0 8px 0;
	}

	iframe {
		pointer-events: none;
	}
}


/* override cookie consent hover style */

.cc-window {
	background-color: #e6ebf0 !important; ;
}

.cc-btn {
	background-color:#ffd422 !important;
	color:#222 !important;
	border-color:transparent;
}
.cc-btn:hover {
	background-color:#1da1f2 !important;
	color:#fff !important;
}

.pages {
	h3 {
		font-weight: 400;
	}
}

blockquote {
	font-family: Georgia, serif;
	font-size: 18px;
	font-style: italic;
	width: 100%;
	margin: 60px 0;
	padding: 20px 30px 20px 60px;
	line-height: 1.45;
	position: relative;
	color: #222;
}

blockquote:before {
	display: block;
	padding-left: 10px;
	content: "\201C";
	font-size: 100px;
	position: absolute;
	left: -10px;
	top: -20px;
	color: #7a7a7a;
}

blockquote cite {
	color: #666;
	font-size: 16px;
	display: block;
	margin-top: 5px;
}

blockquote cite:before {
	content: "\2014 \2009";
}