/*------------------------------------------------------------------
[Module Hero]
*/

.module-hero {
	background-color: $pure-white;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: 50% 0;
	position: relative;
	padding: 0;
	height: 70vh;
	overflow: hidden;
	&.parallax {
		background-color: transparent;
	}
}

.hero-caption-bg {
	position: absolute;
	left: 0;
	bottom: 0;
	width: 100%;
	height: 30vh;


	background: -moz-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,0.7) 100%); /* FF3.6-15 */
	background: -webkit-linear-gradient(top, rgba(0,0,0,0) 0%,rgba(0,0,0,0.7) 100%); /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0.7) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#b3000000',GradientType=0 );
}

.hero-caption-bottom {
	position: absolute;
	left: 0;
	bottom: 0;
	color: white;
	width: 100%;
	font-family: 'Lato', sans-serif;
	letter-spacing: 4px;
	font-size: 20px;

	.container .row {
		padding: 10px 15px;
	}
}

@media only screen and (min-width : 320px) {
	.hero-caption-bottom {
		font-size: 22px;
	}
}

@media only screen and (min-width : 480px) {
	.hero-caption-bottom {
		font-size: 24px;
	}
}

@media only screen and (min-width : 768px) {
	.hero-caption-bottom {
		font-size: 28px;
	}

}

@media only screen and (min-width : 992px) {
	.hero-caption-bottom {
		font-size: 32px;
	}

}

@media only screen and (min-width : 1200px) {
	.hero-caption-bottom {
		font-size: 36px;
	}

}

.flex-control-nav {
	bottom: 0;
}

.hero-caption {
	position: relative;
	display: table;
	height: 100%;
	width: 80%;
	margin: 0 auto;
	z-index: 2;
}

.hero-text {
	position: relative;
	display: table-cell;
	vertical-align: middle;
	height: 100%;
	font-family: $headings-font-family;
	font-size: 30px;
	font-weight: 400;
	h1 {
		font-family: $headings-font-family;
		font-size: 60px;
		font-weight:600;
	}
}

@media (max-width: $screen-sm-max) {
	.hero-text {
		font-size: 36px;
		h1 {
			font-size: 60px;
		}
	}
}

@media (max-width: $screen-xs-max) {
	.hero-text {
		font-size: 20px;
		h1 {
			font-size: 40px;
		}
	}
}


.fullheight {
	height: 100vh;
}

.hero-h10 {
	height: 10vh;
}

.hero-h20 {
	height: 20vh;
}

.hero-h30 {
	height: 30vh;
}

.hero-h40 {
	height: 40vh;
}

.hero-h50 {
	height: 50vh;
}

.hero-h60 {
	height: 60vh;
}

.hero-h70 {
	height: 70vh;
}

.hero-h80 {
	height: 80vh;
}

.hero-h90 {
	height: 90vh;
}

@media(max-width: $screen-sm-max) {
	.module-hero {
		height: auto;
		padding: 240px 0;
	}
}

.animated-background {
	-webkit-animation: animated-background 800s linear infinite;
	animation: animated-background 800s linear infinite;
	background-repeat: repeat-x !important;
}

@-webkit-keyframes animated-background {
	from {
		background-position: 0 0;
	}
	to {
		background-position: -50000px 0;
	}
}

@keyframes animated-background {
	from {
		background-position: 0 0;
	}
	to {
		background-position: -50000px 0;
	}
}



